<template>
    <div class="mt-5">

        <h1 class="text-white" style="margin-top: 1.2em; font-size: 4.5em;">¡Hola!</h1>
        <h1 class="text-white" style="font-size: 2.1em;">Bienvenido a</h1>
        <h1 class="text-white" style="font-size: 4.4em;">CABA</h1>
        
        <div class="d-grid " >
          <button type="button" 
          style="width: 15em; margin: 0 auto;"
            class="btn bg-magenta text-white mt-5 "
            @click="goToLogin()">
            Ingresar
          </button>
        </div>    

    </div>
      
</template>

<script>
import router from "@/router";

export default {
  computed: {

  },
  data() {
    return {
    }
  },
  methods: {
    goToLogin(){
        router.push({ name:'login' });
    },
  },
}

</script>
import { createRouter, createWebHashHistory } from 'vue-router'
//import HomeView from '../views/HomeView.vue';
//import AuthView from '../views/AuthView.vue';
import WelcomeView from '../views/WelcomeView.vue';

const routes = [
  {
    path: '/',
    name: 'welcome',
    component: WelcomeView
  },
  // {
  //   path: '/welcome',
  //   name: 'welcome',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/WelcomeView.vue')
  // },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/AuthView.vue')
  },
  {
    path: '/asistencia',
    name: 'asistencia',
    component: () => import(/* webpackChunkName: "about" */ '../views/PhotoCheckView.vue')
  },
  {
    path: '/asistenciaRegistrada/:id',
    name: 'asistencia-registrada',
    component: () => import(/* webpackChunkName: "about" */ '../views/FeedbackView.vue')
  },
  
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router

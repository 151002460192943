<template>
  <div class="container-center mt-5" >
      <WelcomeComponent/>
  </div>
</template>

<script>

import WelcomeComponent from '@/components/auth/WelcomeComponent.vue';


export default {
  components:{
    WelcomeComponent
  },
computed: {

},
data() {
  return {
  }
},
methods: {
},
}

</script>

<style>
.container-center{
  margin: auto;
  padding: 2em;
}
</style>
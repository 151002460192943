import { createStore } from 'vuex'

export default createStore({
  state: {
    userData: {},
    isLoading: false,
    version:'0.0.0.beta',
    login:false,
  },
  getters: {
  },
  mutations: {
    LOGIN_SUCCESS(state, data) {
      //state.login = true
      state.userData = data
    },
    LOGOUT(state) {
        state.login = false;
        state.userData = {};
    },
  },
  actions: {
  },
  modules: {
  }
})

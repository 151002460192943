<template >
  <div :class="isLogin ? 'bg-purple' : ''" :style="isLogin ? 'position: absolute; width: 100%; top: 0; height: 100vh;': ''">
      <!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav> -->
    <!-- <HeaderComponent v-if="isLogged" ></HeaderComponent> -->

    <Loading v-model:active="isLoading" :can-cancel="false" :on-cancel="onCancel" :is-full-page="fullPage">
        <div>
          <div class="spinner-grow text-success" style="width: 5rem; height: 5rem;" role="status">
            <!-- <span class="sr-only">Cargando...</span> -->
            
          </div>
        </div>
      </Loading>

    <router-view/>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import 'vue-loading-overlay/dist/css/index.css';

// import HeaderComponent from "./shared/components/HeaderComponent";

 export default{
  components: {
    Loading,
    //HeaderComponent,
  },
  computed:{
    isLoading(){
      return this.$store.state.isLoading;
    },
    isLogged(){
      return this.$store.state.login;
    }
  },
  data(){
    return{
      fullPage: true,
      isLogin:false
    }
  },
  methods:{
    onCancel() {
    },
  },
  watch: {
    $route(to) {
      // Actualizamos la propiedad isLogin basándonos en la ruta actual
      console.log('ruta', to);
      this.isLogin = to.name === 'login' || to.name === 'welcome' || to.name === '';
    },
  },
 }
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
